import React from 'react';
import './styles.css';
import { BlockSevenType } from '../../../blog/constant';

type Props = {
  blog: BlockSevenType;
};

export default function BlockSeven({ blog }: Props): JSX.Element {
  const {
    backgroundColor, title, descriptionList, reviews, subTitle, description,
  } = blog;
  return (
    <div className='full-viewport flex background-default' style={{ backgroundColor }}>
      <div className='flex column fix-size-container block-seven-text'>
        <div className='flex column mb-5'>
          <h2 className='text-h2 bold mb-3'>{title}</h2>
          <div className="eight-cards">
            {descriptionList.map((item) => (
              <div key={item.description} className="card">
                <img className='card-image' src={item.image} alt="card-image" />
                <p className='text bold'>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
        <p className="black second mb-5" dangerouslySetInnerHTML={{ __html: description || '' }}></p>
        <div className="flex column">
            <h2 className='text-h2 bold mb-3'>{subTitle}</h2>
            <div className="reviews">
              {reviews.map((item) => (
                <div key={item.title} className="review">
                  <div className='review-photo'>
                    <div className="avatar" style={{ backgroundImage: item.image }}></div>
                  </div>
                  <div className="review-header-text">
                    <p className='default-title'>{item.title}</p>
                    <p className='default-text'>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
      </div>
    </div>
  );
}
