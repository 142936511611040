import React, { useEffect } from 'react';
import './styles.css';

type Props = {
  backgroundColor: string;
  price: number;
  discount: number;
};

export default function FloatButton({ backgroundColor }: Props): JSX.Element {
  const [showComponent, setShowComponent] = React.useState(false);

  const handleClick = () => {
    // eslint-disable-next-line no-restricted-globals
    location.href = 'https://dentalconsultingcogmailcom.lms.softbook.app/shop/item/6462';
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 400 && document.body.scrollHeight - window.scrollY >= 1000) {
        setShowComponent(true);
      } else {
        setShowComponent(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='float-button-wrapper' style={{ bottom: showComponent ? '0px' : '-50px' }}>
      <button onClick={handleClick} className='float-button text white' style={{ backgroundColor }}>
        Купить курс
      </button>
    </div>
  );
}
