import React from 'react';
import './styles.css';
import { BlockFiveType } from '../../../blog/constant';

type Props = {
  blog: BlockFiveType;
};

export default function BlockFive({ blog }: Props): JSX.Element {
  const {
    backgroundImage, title, secondTitle, description,
  } = blog;
  return (
    <div className='full-viewport flex background-default' style={{ backgroundImage }}>
      <div className='flex column fix-size-container block-five'>
        <h2 className='text-h2 bold mb-2'>{title}</h2>
        {secondTitle && <h2 className='default-title bold mb-4'>{secondTitle}</h2>}
        <p className='black bold' dangerouslySetInnerHTML={{ __html: description || '' }}></p>
      </div>
    </div>
  );
}
