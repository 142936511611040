import React from 'react';
import './style.css';
import { WishesItem, wishes } from './constants';

export default function WishesPage() {
  return (
    <div className='wishes-wrapp full-viewport'>
    <div className='wishes-background full-viewport flex'>
      <div className='fix-size-container flex'>
        <h2 className='text-h2 mb-3'>ПРОГРАММА ИСПОЛНЕНИЯ ЖЕЛАНИЙ</h2>
        <p className='default-text mb-4'>
          МОЯ ЦЕЛЬ — СДЕЛАТЬ ВАС ЗДОРОВЫМИ, БОГАТЫМИ И СЧАСТЛИВЫМИ В МАКСИМАЛЬНО КОРОТКИЕ СРОКИ.
        </p>
        <div className='wishes-card-wrapp flex'>
          {wishes.map((wish: WishesItem) => (
            <div className='wish-card mb-4 flex' key={wish.title}>
              <img className='card-image mb-4' src={wish.img} alt={wish.title} />
              <h3 className='card-title'>{wish.title}</h3>
              <p className='card-description'>{wish.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  );
}
