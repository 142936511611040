import React from 'react';
import './styled.css';
import { RefTypes } from '../constants';

type Props = {
  showSideBar: boolean;
  handleShowSideBar: () => void;
  handleScrollIntoView: (type: RefTypes) => void;
};

export default function SideBar({ showSideBar, handleShowSideBar, handleScrollIntoView }: Props): JSX.Element {
  return (
    <nav className={`desktop-hide nav ${showSideBar ? 'active' : ''}`}>
      <img
        src='../static/images/close.svg'
        alt='close'
        className='close'
        onClick={handleShowSideBar}
      />
      <ul className='header__list'>
        <li><a onClick={() => handleScrollIntoView('aboutMe')}>Обо мне</a></li>
        <li><a onClick={() => handleScrollIntoView('about')}>Что это такое?</a></li>
        <li><a onClick={() => handleScrollIntoView('review')}>Отзывы</a></li>
        <li><a onClick={() => handleScrollIntoView('contacts')}>Контакты</a></li>
      </ul>
      <div className='nav-image-wrapp'>
        <img src='../static/images/eye.png' alt='eye' className='eye' />
      </div>
    </nav>
  );
}
