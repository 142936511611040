export enum Messangers {
  TELEGRAM = 'telegram',
  INSTAGRAM = 'instagram',
  WHATSAPP = 'whatsapp',
}

export const messangerLinks = {
  [Messangers.TELEGRAM]: 'https://t.me/hipno_healer',
  [Messangers.INSTAGRAM]: 'https://www.instagram.com/german_dashkevych?igsh=OGQ5ZDc2ODk2ZA==',
  [Messangers.WHATSAPP]: 'https://wa.me/+48575491306',
};
