import React, { RefObject } from 'react';
import './style.css';

type Props = {
  reviewRef: RefObject<HTMLDivElement>;
};

export default function VideoReviews({ reviewRef }: Props): JSX.Element {
  return (
    <div ref={reviewRef} className='video-reviews full-viewport flex'>
      <div className='main-description fix-size-container flex'>
        <h2 className='text-h2 bold mb-4'>Моя терапия сделала их жизнь лучше!</h2>
        <div className='video-wrapper'>
          <iframe
            src="https://www.youtube.com/embed/KyKQj85mzQo?si=DsYu4qqW5ZhStT_h"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/LvSlmFtWYhs?si=uwmIoTmQP_7H8Qoy"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/UCTBKTe98lo?si=HxavzBkD-XzUWvHK"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/86D3VaGsLTg?si=8fM_ASIx7C8lIzB8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/XdJ_IZs_tfA?si=fFDwdwFECjU0WdzH"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/MOgT2SeyI9E?si=M1L1m9SvefnOQlt6"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
