import React from 'react';

export default function SuccessPage() {
  return (
    <div className='block-eight full-viewport flex background-default'>
      Оплата прошла успешно <br />
      Скоро с вами свяжется наш менеджер!
    </div>
  );
}
