import React, { RefObject } from 'react';
import './style.css';
import DescriptionPage from './Description/DescriptionPage';
import PointsPage from './Points/PointsPage';
import DiplomPage from './DiplomPage/DiplomPage';
import AboutMe from './AboutMe/AboutMe';
import TherapyDescription from './TherapyDescription/TherapyDescription';
import VideoReviews from './VideoReviews/VideoReviews';
import WishesPage from './Wishes/WishesPage';
import HowIWork from './HowIWork/HowIWork';
import { RefTypes } from '../constants';
import Contacts from './Contacts/Contacts';

type Props = {
  aboutRef: RefObject<HTMLDivElement>;
  aboutMeRef: RefObject<HTMLDivElement>;
  reviewRef: RefObject<HTMLDivElement>;
  contactsRef: RefObject<HTMLDivElement>;
  handleScrollIntoView: (type: RefTypes) => void;
};

export default function LandingPage({
  aboutRef, aboutMeRef, reviewRef, contactsRef, handleScrollIntoView,
}: Props): JSX.Element {
  return (
    <div>
      <DescriptionPage handleScrollIntoView={handleScrollIntoView} />
      <PointsPage />
      <AboutMe aboutMeRef={aboutMeRef} />
      <DiplomPage />
      <TherapyDescription aboutRef={aboutRef} handleScrollIntoView={handleScrollIntoView} />
      <VideoReviews reviewRef={reviewRef} />
      <HowIWork />
      <WishesPage />
      <Contacts contactsRef={contactsRef} />
    </div>
  );
}
