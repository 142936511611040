import React from 'react';
import './style.css';
import { PointsItem, points } from './constants';

export default function PointsPage(): JSX.Element {
  return (
    <div className='points-wrapp full-viewport'>
      <div className='points-background full-viewport flex'>
        <div className='fix-size-container flex'>
          <h2 className='text-h2 mb-5'>ЭТО ВЕДЬ ПРО ВАС, ВЕРНО ?</h2>
          <div className='point-card-wrapp flex'>
            {points.map((point: PointsItem) => (
              <div className='point-card' key={point.title}>
                <h3 className='card-title'>{point.title}</h3>
                <div className='card-description' dangerouslySetInnerHTML={{ __html: point.description }}></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
