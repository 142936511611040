import React from 'react';
import { BlockOneType } from '../../../blog/constant';
import './styles.css';

type Props = {
  blog: BlockOneType;
};

export default function BlockOne({ blog }: Props): JSX.Element {
  const {
    backgroundImage, backgroundColor, title, descriptionOne, descriptionTwo, list,
  } = blog;

  return (
    <div className='block-one full-viewport fix-size-container-2 flex' style={{ backgroundImage, backgroundColor }}>
      <div className='block-one-text'>
        <h1 className='text-h1 white'>{title}</h1>
        <p className='text-h2 white'>{descriptionOne}</p>
        <p className='default-text white'>{descriptionTwo}</p>
        <ul className='default-text white'>
          {list.map((item) => <li key={item}>{item}</li>)}
        </ul>
      </div>
    </div>
  );
}
