import React from 'react';
import './style.css';

export default function HowIWork(): JSX.Element {
  return (
    <div className='how-work-wrapper full-viewport flex'>
      <div className='main-description fix-size-container flex'>
        <h2 className='text-h2 bold mb-5'>Как я работаю</h2>
        <div className="card-wrapper">
          <div className="card">
            <p className='default-title mb-2'>
              Оставьте заявку на бесплатную консультацию:
            </p>
            <p className='default-text'>
              Оставьте свои контактные данные, и в течение 24 часов я свяжусь с вами для уточнения даты и
              времени проведения консультации.
            </p>
          </div>
          <div className="card">
            <p className='default-title mb-2'>
              Пройдите бесплатную консультацию:
            </p>
            <p className='default-text'>
              Мы проведем консультацию онлайн в Skype, Telegram или WhatsApp. Здесь мы определим ваши цели
              и ожидаемые результаты. Разберем текущую ситуацию и выясним, что мешает вам.
            </p>
          </div>
          <div className="card">
            <p className='default-title mb-2'>
              Принятие решения:
            </p>
            <p className='default-text'>
              После согласования деталей на консультации, вы примете решение и выберете удобный для вас способ оплаты.
            </p>
          </div>
          <div className="card">
            <p className='default-title mb-2'>
              Составляем пошаговый план совместной работы:
            </p>
            <p className='default-text'>
              На основе обсужденных деталей разработаем план действий для достижения ваших целей. Эта програма
              предназначена для полного преобразования вашей жизни. Мы устраним все блоки и ограничения, которые
              мешают вам жить полной жизнью и вы переживете удивительные перемены. Максимальная
              длительность программы – 8 недель. Сессии проводятся онлайн с длительностью от 1,5 до 2 часов.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
