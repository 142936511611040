import React from 'react';
import { BlockTwoType, CardsList } from '../../../blog/constant';
import './styles.css';

type Props = {
  blog: BlockTwoType;
};

export default function BlockOne({ blog }: Props): JSX.Element {
  const {
    backgroundColor, title, description, list,
  } = blog;

  return (
    <div className='block-two full-viewport fix-size-container-2 flex' style={{ backgroundColor }}>
      <h1 className='text-h1 white mb-2'>{title}</h1>
      <p className='text-h2 white'>{description}</p>
      <div className='blog-card-wrapp flex'>
        {list.map((item: CardsList) => (
          <div className='blog-card mb-4' key={item.title}>
            <div className='blog-card-image' style={{ backgroundImage: item.image }} />
            <div className='blog-card-text'>
              <h3 className='blog-card-title'>{item.title}</h3>
              <p className='blog-card-description'>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
