import React, { RefObject } from 'react';
import './style.css';
import { RefTypes } from '../../constants';

type Props = {
  aboutRef: RefObject<HTMLDivElement>;
  handleScrollIntoView: (type: RefTypes) => void;
};

export default function TherapyDescription({ aboutRef, handleScrollIntoView }: Props): JSX.Element {
  return (
    <div ref={aboutRef} className='therapy-desription full-viewport flex'>
      <div className='main-description white fix-size-container flex'>
        <h2 className='text-h2 bold mb-3'>Что такое холистическая терапия?</h2>
        <p className='text'>
          Это комплексный подход к исцелению с проработкой подсознания.
          Ибо именно оно хранит истинные данные о вашем "я" и открывает доступ
          к самым отдаленным уголкам памяти.
        </p>
        <p className='text'>
          Работая с подсознанием напрямую, мы вместе с вами обойдем критический фактор — естественный
          психологический барьер, мешающий <b>кардинальным переменам</b>. Мы с вами вместе выявим
          причинно-следственные связи, а затем заменим вредоносные и ошибочные установки полезными и правильными.
        </p>
        <p className='text'>
          Вы удивитесь, насколько легко разрешаются трудности.
          Не обязательно полагаться на силу воли, подавлять эмоции, проводить регулярные медитации
          или мириться с тяжелым бременем. Вы просто избавитесь от них раз и навсегда.

        </p>
        <p className='text'>
          В моем арсенале лишь эффективные, проверенные, и, что самое важное,
          абсолютно безопасные методы, которые преобразили жизни многих людей к лучшему!
        </p>
        <button
          onClick={() => handleScrollIntoView('contacts')}
          className="outline-button bold"
        >
          ЗАПИСАТЬСЯ НА БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ
        </button>
      </div>
    </div>
  );
}
