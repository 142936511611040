import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './App.css';
import './common.css';
import MainPage from './pages/MainPage/MainPage';
import BlogTemplate from './pages/BlogTemplate/BlogTemplate';
import { blog } from './blog/constant';
import SuccessPage from './pages/SuccessPage/SuccessPage';

const dynamicRoutes = blog.map((item) => ({
  path: item.id,
  element: <BlogTemplate blogObject={item} />,
}));

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '/success',
    element: <SuccessPage />,
  },
  ...dynamicRoutes,
]);

function App(): JSX.Element {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
