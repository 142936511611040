import React, { useEffect } from 'react';
import { BlogType } from '../../blog/constant';
import BlockOne from './components/BlockOne';
import BlockTwo from './components/BlockTwo';
import BlockThree from './components/BlockThree';
import BlockFour from './components/BlockFour';
import BlockFive from './components/BlockFive';
import BlockSix from './components/BlockSix';
import BlockSeven from './components/BlockSeven';
import BlockEight from './components/BlockEight';
// import Contacts from '../Contacts/Contacts';
import FloatButton from './components/FloatButton';
import Footer from './components/Footer';

type Props = {
  blogObject: BlogType;
};

export default function BlogTemplate({ blogObject }: Props): JSX.Element {
  useEffect(() => {
    if (!localStorage.getItem('time')) {
      const today = new Date();
      localStorage.setItem('time', today.toString());
    }
  }, []);

  const {
    courseInfo, blockOne, blockTwo, blockThree, blockFour, blockFive, blockSix, blockSeven, blockEight,
  } = blogObject;

  return (
    <div className='relative'>
      <FloatButton backgroundColor={courseInfo.background} price={courseInfo.price} discount={courseInfo.discount} />
      <BlockOne blog={blockOne} />
      <BlockTwo blog={blockTwo} />
      <BlockThree blog={blockThree} />
      <BlockFour blog={blockFour} />
      <BlockFive blog={blockFive} />
      <BlockSix blog={blockSix} />
      <BlockSeven blog={blockSeven} />
      <BlockEight blog={blockEight} />
      {/* <Contacts backgroundColor={courseInfo.contactColor} /> */}
      <Footer />
    </div>
  );
}
