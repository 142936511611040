import React from 'react';
import './styles.css';
import { BlockFourType } from '../../../blog/constant';

type Props = {
  blog: BlockFourType;
};

export default function BlockFour({ blog }: Props): JSX.Element {
  const {
    backgroundImage, title, description,
  } = blog;
  return (
    <div className='full-viewport flex background-default' style={{ backgroundImage }}>
      <div className='flex column fix-size-container'>
        <h2 className='text-h2 white bold mb-3'>{title}</h2>
        <p className="white" dangerouslySetInnerHTML={{ __html: description || '' }}></p>
      </div>
    </div>
  );
}
