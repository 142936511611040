import React, { RefObject } from 'react';
import './style.css';

type Props = {
  aboutMeRef: RefObject<HTMLDivElement>;
};

export default function AboutMe({ aboutMeRef }: Props): JSX.Element {
  return (
    <div ref={aboutMeRef} className='full-viewport'>
      <div className='about-me-background full-viewport flex'>
        <div className='fix-size-container flex'>
          <div className='main-description flex'>
            <h2 className='text-h1 mb-2 h'>Я готов вам помочь</h2>
            <div className="about-me-card">
              <div>
                <img
                  src='../static/images/german-9.jpeg'
                  alt='Herman Dashkievich'
                />
              </div>
              <div className="about-me-text">
                <p className="about-me-title mb-1">
                  Здравствуйте, меня зовут Герман Дашкевич и я эксперт в работе с подсознанием, целитель,
                  Мастер Гипнотерапевт.
                </p>
                <p className="text">
                  С моей программой "Restart your Life" я <span className='bold'>ГАРАНТИРОВАННО</span>&nbsp;
                  излечу вас от проблем заставляющих вас уничтожать свою жизнь.
                  Мы сфокусируемся на разрешении ваших проблем и лечении внутренних конфликтов.
                  Моя цель - помочь вам достичь
                  психологического и материального благополучия.
                  <br />Приглашаю вас пройти путь к освобождению и восстановлению контроля над своей жизнью.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
