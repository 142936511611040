import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { BlockEightType } from '../../../blog/constant';

type Props = {
  blog: BlockEightType;
};

export default function BlockEight({ blog }: Props) {
  const { title, backgroundImage, list } = blog;

  return (
    <div className='block-eight full-viewport flex background-default' style={{ backgroundImage }}>
      <div className='flex column fix-size-container'>
        <h2 className='text-h2 bold mb-3'>{title}</h2>
        <div>
          {list.map((item) => (
            <Accordion key={item.title}>
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <p className='default-title'>{item.title}</p>
              </AccordionSummary>
              <AccordionDetails>
                <p className='default-text'>
                  {item.description}
                </p>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
}
