import React from 'react';
import './styles.css';
import { BlockSixType } from '../../../blog/constant';

type Props = {
  blog: BlockSixType;
};

export default function BlockSix({ blog }: Props): JSX.Element {
  const {
    backgroundImage, backgroundColor, title, list,
  } = blog;
  return (
    <div className='block-six full-viewport flex' style={{ backgroundImage, backgroundColor }}>
      <div className='main-description fix-size-container'>
        <h2 className='text-h2 white bold mb-5'>{title}</h2>
        <div className="card-wrapper">
          {list.map((item) => (
            <div key={item.title} className="card white">
              <p className='default-title white mb-2'>
                {item.title}
              </p>
              <p className='default-text white'>
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
