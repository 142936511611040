import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import LandingPage from '../LandingPage';
import { RefTypes } from '../../constants';

function MainPage(): JSX.Element {
  const [scroll, setScroll] = useState<number>(0);
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const aboutMeRef = useRef<HTMLDivElement | null>(null);
  const reviewRef = useRef<HTMLDivElement | null>(null);
  const contactsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollIntoView = (type: RefTypes) => {
    const refs = {
      about: aboutRef,
      aboutMe: aboutMeRef,
      review: reviewRef,
      contacts: contactsRef,
    };
    refs[type]?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Header scroll={scroll} handleScrollIntoView={scrollIntoView} />
      <LandingPage
        aboutRef={aboutRef}
        aboutMeRef={aboutMeRef}
        reviewRef={reviewRef}
        contactsRef={contactsRef}
        handleScrollIntoView={scrollIntoView}
      />
    </div>
  );
}

export default MainPage;
