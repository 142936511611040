import React from 'react';
import './style.css';
import { RefTypes } from '../../constants';

type Props = {
  handleScrollIntoView: (type: RefTypes) => void;
};

export default function DescriptionPage({ handleScrollIntoView }: Props): JSX.Element {
  return (
    <div className='description-wrapp full-viewport flex'>
      <div className='fix-size-container flex'>
        <h1>Трансформативная Гипнотерапия</h1>
        <p>
          Чувствуешь что потерялся?
          <br />Устаешь от саморазрушения?
          <br />Позволь себе найти ответ.
        </p>
        <div className="button-wrapp">
          <button onClick={() => handleScrollIntoView('aboutMe')} className="filled-button">
            Обо мне
          </button>
          <button onClick={() => handleScrollIntoView('contacts')} className="outline-button">
            Записаться на консультацию
          </button>
        </div>
      </div>
    </div>
  );
}
