import React, { RefObject } from 'react';
import './style.css';
import { Messangers, messangerLinks } from './constants';

type Props = {
  contactsRef?: RefObject<HTMLDivElement>;
  backgroundColor?: string;
};

export default function Contacts({ contactsRef, backgroundColor }: Props): JSX.Element {
  const redirectToMessanger = (type: Messangers) => {
    window.open(messangerLinks[type], '_blank');
  };

  return (
    <div ref={contactsRef} className='full-viewport'>
      <div style={{ backgroundColor }} className='contacts-wrapper full-viewport flex'>
        <div className='fix-size-container flex'>
          <p className='default-text'>
            Жмите на кнопку ниже и пишите мне в удобном мессенджере.
          </p>
          <div className='full-width button-wrapper'>
            <button className='outline-button telegram' onClick={() => redirectToMessanger(Messangers.TELEGRAM)}>
              <img src='../static/images/telegram.svg' alt='logo' />
              TELEGRAM
            </button>
            <button className='outline-button instagram' onClick={() => redirectToMessanger(Messangers.INSTAGRAM)}>
              <img src='../static/images/instagram.svg' alt='logo' />
              INSTAGRAM
            </button>
            <button className='outline-button whatsapp' onClick={() => redirectToMessanger(Messangers.WHATSAPP)}>
              <img src='../static/images/whatsapp.svg' alt='logo' />
              WHAT'S APP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
