import React from 'react';
import './style.css';

export default function DiplomPage(): JSX.Element {
  return (
    <div className='diplom full-viewport flex'>
      <div className='main-description fix-size-container flex'>
        <h2 className='text-h2 bold mb-5'>Я ПРОДЕЛАЛ ПРЕКРАСНЫЙ ПУТЬ ЧТОБЫ СПАСАТЬ ЧЕЛОВЕЧЕСКИЕ ЖИЗНИ</h2>
        <img src="../../static/images/diplom.jpeg" alt="Diploma" />
      </div>
    </div>
  );
}
