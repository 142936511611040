import React from 'react';

export default function Footer(): JSX.Element {
  return (
    <footer className='footer-wrapper'>
      <div className='fix-size-container-2'>
        <div className='footer_main'>
          <div>
            <p className='footer_main-text white'>Herman Dashkevych</p>
            <p className='footer_main-text white'>NIP 8992896787</p>
            <a href="tel:+48575491306" className='footer_main-text white'>+48575491306</a>
            <a href="mailto:dentalconsulting.co@gmail.com" className='footer_main-text white'>
              dentalconsulting.co@gmail.com
            </a>
            <p className='footer_main-text white'>
              © All rights reserved
            </p>
          </div>
          <div className='footer_link'>
            <a href="https://docs.google.com/document/d/10f_ByIviJMzer_cEA81h6BmUmY6vRwLIbqYFSPLxEIQ/export?format=pdf" className='footer_main-text white'>
              Договор оферты
            </a>
            <a href="https://docs.google.com/document/d/11yJwSPeyk401VWy9LksDwjmF82dJ2f9NF1l_GrwPpNo/export?format=pdf" className='footer_main-text white'>
              Политика конциденциальности
            </a>
          </div>
        </div>
        <p className='footer_small white'>
          Никакая часть настоящей публикации или ее дизайн не могут быть воспроизведены,
          переданы или использованы в какой-либо форме или любыми способами, электронными,
          механическими, копированием, записью или иным способом. Нарушение карается согласно
          Закону Украины об авторских правах.
        </p>
      </div>
    </footer>
  );
}
