import React, { useState } from 'react';
import './styled.css';
import SideBar from './SideBar';
import { RefTypes } from '../constants';

type Props = {
  scroll: number;
  handleScrollIntoView: (type: RefTypes) => void;
};

export default function Header({ scroll, handleScrollIntoView }: Props): JSX.Element {
  const [showSideBar, toggleShowSideBar] = useState<boolean>(false);

  const handleShowSideBar = (): void => {
    toggleShowSideBar(!showSideBar);
  };

  const onScrollView = (type: RefTypes): void => {
    toggleShowSideBar(false);
    handleScrollIntoView(type);
  };

  return (
    <header className={`header ${scroll > 800 ? 'header-scrolled' : ''}`}>
      <div className='header-wrapp flex'>
        <ul className='header__list flex mobie-hide'>
          <li><a onClick={() => handleScrollIntoView('aboutMe')}>Обо мне</a></li>
          <li><a onClick={() => handleScrollIntoView('about')}>Что это такое?</a></li>
          <li><a onClick={() => handleScrollIntoView('review')}>Отзывы</a></li>
          <li><a onClick={() => handleScrollIntoView('contacts')}>Контакты</a></li>
        </ul>
      </div>
      <div className='burger-menu desktop-hide' onClick={handleShowSideBar}>
        <img src='../static/images/burger_menu.svg' alt='burger menu' />
      </div>
      <SideBar
        showSideBar={showSideBar}
        handleShowSideBar={handleShowSideBar}
        handleScrollIntoView={onScrollView}
      />
    </header>
  );
}
