/* eslint-disable max-len */
export type CourseInfo = {
  price: number;
  discount: number;
  background: string;
  contactColor: string;
};

export type BlockOneType = {
  backgroundImage: string;
  backgroundColor: string;
  title: string;
  descriptionOne: string;
  descriptionTwo: string;
  list: string[];
};

export type CardsList = {
  title: string;
  description: string;
  image?: string;
};

export type SmallCard = {
  description: string;
  image: string;
};

export type BlockTwoType = {
  backgroundColor: string;
  title: string;
  description?: string;
  list: CardsList[];
};

export type BlockThreeType = {
  backgroundColor: string;
  image: string;
  mainText: string;
  description?: string;
};

export type BlockFourType = {
  backgroundImage: string;
  title: string;
  description?: string;
};

export type BlockFiveType = {
  backgroundImage: string;
  title: string;
  secondTitle?: string;
  description: string;
};

export type BlockSixType = {
  backgroundImage: string;
  backgroundColor: string;
  title: string;
  list: CardsList[];
};

export type BlockSevenType = {
  backgroundColor: string;
  title: string;
  descriptionList: SmallCard[];
  subTitle: string;
  reviews: CardsList[];
  description: string;
};

export type BlockEightType = {
  backgroundImage: string;
  title: string;
  list: CardsList[];
};

export type BlogType = {
  id: string;
  courseInfo: CourseInfo;
  blockOne: BlockOneType;
  blockTwo: BlockTwoType;
  blockThree: BlockThreeType;
  blockFour: BlockFourType;
  blockFive: BlockFiveType;
  blockSix: BlockSixType;
  blockSeven: BlockSevenType;
  blockEight: BlockEightType;
};

export const blog: BlogType[] = [
  {
    id: '/stop-trevoga',
    courseInfo: {
      price: 60,
      discount: 83,
      background: '#f48742',
      contactColor: 'rgb(28 80 67)',
    },
    blockOne: {
      backgroundImage: 'url(static/images/blog/1.jpg)',
      backgroundColor: 'rgb(23 68 56)',
      title: '7 шагов к Свободе от тревоги',
      descriptionOne: '7-ми дневный курс по избавлению от тревожных состояний',
      descriptionTwo:
        'Если в твоей жизни стало слишком много страха, неуверенности и непонимания. Действуй прямо сейчас',
      list: [
        'освободись от чувства тревоги',
        'обрети уверенное и стабильное состояние',
        'измени свою жизнь за 7 дней',
        'проверенные методики НЛП и психологии',
      ],
    },
    blockTwo: {
      backgroundColor: 'rgb(28 80 67)',
      title: 'Этот курс для тебя, если есть:',
      // list of cards should be multiple 3
      list: [
        {
          title: 'Тревога',
          description: 'Избавься от назойливого чувства тревоги и обрести внутренний покой',
          image: 'url(static/images/blog/2.jpg)',
        },
        {
          title: 'Усталость',
          description: 'Забудь про постоянную усталость и почувствуй себя отдохнувшим и полным сил',
          image: 'url(static/images/blog/3.jpg)',
        },
        {
          title: 'Нестабильность',
          description: 'Научись самостоятельно работать со своим состоянием',
          image: 'url(static/images/blog/4.jpg)',
        },
        {
          title: 'Недовольство',
          description: 'Почувствуй удовлетворение собой и своей жизнью',
          image: 'url(static/images/blog/5.jpg)',
        },
        {
          title: 'Нерешительность',
          description: 'Начни предпринимать решающие шаги вперед к своей мечте',
          image: 'url(static/images/blog/6.jpg)',
        },
        {
          title: 'Невнимательность',
          description: 'Найди у себя то что крадет твое внимание и прокачай осознанность',
          image: 'url(static/images/blog/7-0.jpg)',
        },
      ],
    },
    blockThree: {
      backgroundColor: 'rgb(165 184 179)',
      image: 'static/images/german-9.jpeg',
      mainText: 'Привет, я Герман Дашкевич. Я врач-стоматолог и мастер по работе с подсознанием и знаю насколько трудным, порой, бывает состояние человека в кресле стоматолога и за его пределами.',
      description:
        'Поэтому я создал этот курс. Моя цель - помочь каждому, кто страдает тревожными расстройствами, паническими атаками и другими навязчивыми состояниями и вернуть возможность жить спокойно и счастливо, имея при этом ресурс для себя и окружающих. <ul class="list-circle"><li>Дипломированный врач.</li><li>Сертифицированный гипнотерапевт.</li><li>Вылечил более 2000 человек.</li><li>Помог более 100 людям найти свое предназначение и начать движение к своей мечте.</li><li>Прошел обучение гипнозу, работе с подсознанием, психологии денег, психологии переговоров, тайм-менеджменту.</li></ul>',
    },
    blockFour: {
      backgroundImage: 'linear-gradient(to bottom, rgb(165 184 179), rgb(5 9 8 / 59%)), url(static/images/blog/9.jpg)',
      title: 'Как будет проходить курс?',
      description: '<p class="text">В курс входит 7 видео уроков длительность 10-20 минут. Которые включают в себя теоретическую информацию и домашнее задание, которое вы получаете для лучшего результата и лучшего понимания на практике.</p><p class="text">Сразу после оплаты ты получишь доступ к платформе в которой и будут храниться уроки. Занимайся в удобное время, ведь платформа открыта 24/7.</p><p class="text">Доступ к курсу активен в течение 90 дней. Ты можешь продвигаться в своем темпе и возвращаться к урокам столько раз, сколько тебе это будет нужно. А после окончания доступа ты можешь со скидкой присоединиться к сообществу и расти вместе с нами. Просто напиши нам. Приобретая этот курс, ты начинаешь путь заботы о себе.</p><p class="text bold">Не медли и сделай первый шаг уже сегодня! Курс доступен по специальной цене – всего 10 $  вместо  60 $</p>',
    },
    blockFive: {
      backgroundImage: 'linear-gradient(to bottom, rgb(165 184 179 / 60%), rgb(28 80 67)), url(static/images/blog/7.jpg)',
      title: 'Преврати этот беспочвенный страх и непонимание того что с тобой происходит в Свободу, Лекгость и Уверенность.',
      secondTitle: 'Преимущества курса:',
      description: '<p class="text">Занимайся, когда удобно. Все что тебе необходимо - до 30 минут ежедневно выделенных для себя.</p><p class="text">Начни двигаться туда, где страшно. Я дам тебе практики и информацию, которая не то что уберет тревогу, а поможет изменить свою жизнь до неузнаваемости.</p><p class="text">Научись управлять своим состоянием не из состояния надо, а из состояния хочу.</p><p class="text">Увеличь свою осознанность. Я дам несколько рабочих практик медитации, которым мы сможем накачать твою “мышцу” осознанности.</p>',
    },
    blockSix: {
      backgroundColor: 'rgb(28 80 67)',
      backgroundImage: 'url(static/images/background-work.png), url(static/images/background-work-reverse.png)',
      title: '7 Уроков',
      list: [
        {
          title: 'Урок 1: Понимание Тревоги',
          description: 'Как она проявляется физически и психологически? Различие между тревогой и стрессом. Я дам вам основные техники быстрого самоуспокоения.',
        },
        {
          title: 'Урок 2: Стратегии Самопомощи в Долгосрочной Перспективе',
          description: 'Расскажу как лучше разработать персональный план для управления тревогой, установка и достижение целей. Покажу как создать журнал тревоги, методы принятия решений и управления временем.',
        },
        {
          title: 'Урок 3: Здоровье и Физический Аспект в Борьбе с Тревогой',
          description: 'Обсудим важность сбалансированного питания и качественного сна в контроле тревожных расстройств. Планирование питания, методы релаксации перед сном, создание ритуалов.',
        },
        {
          title: 'Урок 4: Питание и Сон',
          description: 'Обсудим важность сбалансированного питания и качественного сна в контроле тревожных расстройств. Планирование питания, методы релаксации перед сном, создание ритуалов.',
        },
        {
          title: 'Урок 5: Психологические Аспекты Тревоги',
          description: 'Расскажу о психологических причинах тревоги и их влияние мышления на эмоции. Научу коучинговым вопросам для самоанализа.',
        },
        {
          title: 'Урок 6: Развитие Эмоционального Интеллекта',
          description: 'Узнаете о значении эмоционального интеллекта в управлении стрессом и тревожными реакциями. Научу технике, которую используют клинические психологи для работы с тревожными мыслями на самопознание, управление эмоциями и сочувствие к себе и другим.',
        },
        {
          title: 'Урок 7: Продвинутые Методы Управления Эмоциями',
          description: 'Глубокое понимание эмоций, применение методов медитации и осознанности для более глубокого самопознания и эмоционального контроля. Основы медитации, техники глубокой релаксации и осознанности.',
        },
      ],
    },
    blockSeven: {
      backgroundColor: 'rgb(165 184 179 / 60%)',
      title: 'Результаты после курса:',
      descriptionList: [
        {
          image: 'static/images/blog/7-1.jpeg',
          description: 'Навыки снижения стресса',
        },
        {
          image: 'static/images/blog/7-2.jpeg',
          description: 'Уверенность в себе',
        },
        {
          image: 'static/images/blog/7-3.jpeg',
          description: 'Улучшение физического самочувствия',
        },
        {
          image: 'static/images/blog/7-4.jpeg',
          description: 'Улучшение социальных навыков',
        },
        {
          image: 'static/images/blog/7-5.jpeg',
          description: 'Повышение самоэффективности',
        },
        {
          image: 'static/images/blog/7-6.jpeg',
          description: 'Снизиться уровень тревожности',
        },
      ],
      subTitle: 'Отзывы участников:',
      reviews: [
        {
          image: 'url(static/images/review/review-1.jpeg)',
          title: 'Анна, 27 лет',
          description: 'Я всегда была слишком тревожной, но после курса я научилась управлять своими эмоциями и переживаниями. Теперь я чувствую себя более спокойной и уверенной.',
        },
        {
          image: 'url(static/images/review/review-2.jpeg)',
          title: 'Алексей, 35 лет',
          description: 'Курс помог мне разобраться в своих чувствах и эмоциях. Я научился управлять своими мыслями и переживаниями. Теперь я чувствую себя более спокойным и уверенным.',
        },
        {
          image: 'url(static/images/review/review-3.jpeg)',
          title: 'Мария, 29 лет',
          description: 'Я всегда была слишком тревожной, но после курса я научилась управлять своими эмоциями и переживаниями. Теперь я чувствую себя более спокойной и уверенной.',
        },
      ],
      description: '<p class="default-text">Бонусом к этому будет ежедневное время для себя и своих спокойных размышлений, возможность найти свое истинное призвание и четкое видение своего будущего.</p><p class="text bold">ГАРАНТИЯ Если случится так, что в течение 14 дней после покупки марафона ты поймешь, что он не соответствует твоим ожиданиям и не приносит тебе пользы, пожалуйста, сообщи нам, и мы вернем деньги.</p><p class="default-text">Не стоит откладывать на понедельник! Не игнорируй шанс начать действовать уже сегодня! Присоединяйся к марафону прямо сейчас, наслаждайся тренировками и самой собой!</p>',
    },
    blockEight: {
      backgroundImage: 'linear-gradient(to bottom, rgb(165 184 179 / 60%), rgb(28 80 67)), url(static/images/blog/question.jpg)',
      title: 'Вопросы-ответы',
      list: [
        {
          title: 'Сколько времени занимает каждый урок?',
          description: 'Каждый урок курса занимает около 10-20 минут. Для достижения лучших результатов, вам рекомендуется выполнять домашнее задание и уделять учебе 3-5 дней в неделю.',
        },
        {
          title: 'Нужно ли строго придерживаться программы курса?',
          description: 'Программа курса разработана таким образом, чтобы обеспечить гибкость и легкость в освоении. Она не содержит строгих ограничений и предлагает разнообразные методики и техники, которые каждый может адаптировать под свои нужды. Основной фокус — это внедрение полезных привычек в повседневную жизнь участников курса.',
        },
        {
          title: 'Я никогда не работал(а) над своей тревогой, подойдет ли мне этот курс?',
          description: 'Курс подходит для людей любого возраста и уровня подготовки. Неважно, новичок ты или уже имеешь опыт в управлении своими эмоциями, курс предложит тебе полезные инструменты и техники.',
        },
        {
          title: 'Я уже прохожу лечение. Могу ли я пройти курс?',
          description: 'Если ты уже проходишь лечение, пожалуйста, проконсультируйся со своим врачом перед началом курса. Важно убедиться, что предложенные методики и техники безопасны для тебя в данном жизненном этапе.',
        },
        {
          title: 'Если у меня возникнут вопросы во время прохождения курса, к кому мне обратиться?',
          description: 'Если у тебя остались вопросы или они возникнут во время прохождения курса, ты можешь связаться с нашей службой поддержки. Мы всегда готовы тебе помочь.',
        },
      ],
    },
  },
];
