enum PointsTitle {
  YOURSELF = 'ОТСУТСТВУЕТ ЭНЕРГИЯ',
  HEALTH = 'НИЗКАЯ САМООЦЕНКА',
  ABUNDANCE = 'НЕДОСТАТОК ДЕНЕГ',
  LOVE = 'САМОРЕАЛИЗАЦИЯ',
}

export type PointsItem = {
  title: PointsTitle;
  description: string;
};

export const points: PointsItem[] = [
  {
    title: PointsTitle.YOURSELF,
    description: '<ul><li>Ощущение изнемождения, постоянная усталость, прокрастинация, отсутствие чувства свободы и радости в повседневной жизни.</li><li>Возникает ощущение беспомощности, начинаете что-то, но быстро теряете интерес и бросаете, словно тлеющий светильник, который быстро исчезает.</li></ul>',
  },
  {
    title: PointsTitle.HEALTH,
    description: '<ul><li>Непрерывное сопоставление себя с окружающими, зависимость от мнений и оценок других.</li><li>Затруднение в проявлении силы личности, ощущение неполноценности, неспособность постоять за себя.</li></ul>',
  },
  {
    title: PointsTitle.ABUNDANCE,
    description: '<ul><li>Независимо от заработанных средств, остается ощущение несчастья.</li><li>Постоянный труд 24/7, но финансовые успехи постоянно колеблются.</li><li> Чувство угрозы потери всего, что есть, и возвращения к бедности.</li></ul>',
  },
  {
    title: PointsTitle.LOVE,
    description: '<ul><li>Отсутствие четких целей и направлений мешает достижению личных успехов.</li><li>Сомнения в своих способностях и осознание неправильности принимаемых решений.</li><li>Надежда встретить  человека, который предоставит инструкции по улучшению жизни.</li></ul>',
  },
];
