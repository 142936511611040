import React from 'react';
import './styles.css';
import { BlockThreeType } from '../../../blog/constant';

type Props = {
  blog: BlockThreeType;
};

export default function BlockThree({ blog }: Props): JSX.Element {
  const {
    backgroundColor, image, mainText, description,
  } = blog;
  return (
    <div className='block-three full-viewport fix-size-container-2 flex' style={{ backgroundColor }}>
      <div className='block-three_main-description flex'>
        <h2 className='text-h1 mb-2'>Я готов тебе помочь</h2>
        <div className="block-three_about-me-card">
          <div>
            <img src={image} alt='Herman Dashkievich' />
          </div>
          <div>
            <p className="block-three_about-me-title mb-1">{mainText}</p>
            <p className="block-three_text" dangerouslySetInnerHTML={{ __html: description || '' }}></p>
          </div>
        </div>
      </div>
    </div>
  );
}
