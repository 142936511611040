enum WishesTitle {
  BLOGERS = 'БЛОГЕРАМ',
  DOCTORS = 'ЦЕЛИТЕЛЯМ',
  ILLS = 'БОЛЕЮЩИМ',
  ORGANIZATORS = 'ОРГАНИЗАТОРАМ',
  SUOLS = 'ДУХОВНЫМ',
  ACTORS = 'АКТЕРАМ',
}

export type WishesItem = {
  img: string;
  title: WishesTitle;
  description: string;
};

export const wishes: WishesItem[] = [
  {
    img: '/static/images/bloggers.png',
    title: WishesTitle.BLOGERS,
    description: 'Рассказывая о своем опыте, вы вносите ценный вклад в область ментального здоровья и личностного развития, укрепляя свой авторитет перед широкой аудиторией.',
  },
  {
    img: '/static/images/doctors.png',
    title: WishesTitle.DOCTORS,
    description: 'Это позволит вам глубже понимать самого себя, новые методы оздоровления и целительства, что в свою очередь станет ценным ресурсом для лечения ваших пациентов.',
  },
  {
    img: '/static/images/ills.png',
    title: WishesTitle.ILLS,
    description: 'Пройдите уникальный путь исцеления, а затем поделитесь своей вдохновляющей историей с миром, придав силу тем, кто находится в подобной ситуации.',
  },
  {
    img: '/static/images/oranizators.png',
    title: WishesTitle.ORGANIZATORS,
    description: 'Появится четкое понимание, куда двигаться дальше и достигнуть результатов. Сможете реализовать свой потенциал на 110%.',
  },
  {
    img: 'static/images/souls.png',
    title: WishesTitle.SUOLS,
    description: 'Участие в программе расширит ваше понимание самого себя, вселенной и Творца, создавая возможность поделиться своим уникальным путешествием и трансформацией перед миллионами людей, вдохновляя их на духовные поиски.',
  },
  {
    img: '/static/images/actors.png',
    title: WishesTitle.ACTORS,
    description: 'Эта программа станет не только возможностью для актеров расширить свои творческие горизонты, но и возможностью вновь представить себя миллионам зрителей, поделившись своей историей о личном преобразовании и росте.',
  },
];
